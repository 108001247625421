import { Grid, Hidden, Tab, Tabs } from "@material-ui/core";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import React, { useEffect, useMemo, useState } from "react";
import { ACCOUNT_TYPE, SORT_DIRECTION } from "../../../commons/enum";
import {
  cashBalanceHistoryChartBaseOptions,
  cashBalanceHistoryChartOptionsMobile,
  lineCustomOptionsOnMobile,
  toCashBalanceChartData,
  toPercentageBarDataPoint,
  toTopLivingExpenseCategoriesChartData,
  transactionsComparator,
  useSmallTabsStyles,
} from "../../../commons/transactions.common";
import {
  LastXDaysOverview,
  LineChartComponent,
  LineChartMobileWrapper,
  LoadingBackdrop,
  LoadingBackdropContainer,
  PercentageBar,
  SimpleTransactionList,
  TransactionsTable,
  WithLoadMore,
} from "../../../components";
import AddAccountButton from "../../../components/add-account-button";
import DoughnutChartComponent from "../../../components/doughnut-chart-component";
import { useIncrementalLimits } from "../../../hooks";
import {
  useAccountList,
  useLastXDaysFinanceHistory,
} from "../../../hooks/account.hooks";
import { useIsMobile, useIsTablet } from "../../../hooks/common.hooks";
import { primaryColor, secondaryColor } from "../../../scss/colors.scss";
import { getChartBalanceTitle } from "../../../utils/chart-util";
import {
  expensesCategoryChartColor,
  expensesCategoryChartIcon,
} from "../../../utils/expensesUtils";
import { formatNumberWithDollar } from "../../../utils/numberFormater";
import { useTabStyles } from "../../../utils/styles-util";
import DateFilter from "../components/date-filter";
import IncomeExpenseHorizontalBarChart from "../components/income-expense-horizontal-bar-chart";
import SummaryBlock from "../components/summary-block";

const transactionsInfoTabs = [
  { title: "Account Transactions" },
  { title: "Expense Categories" },
];

const STEP = 10;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}

function AccountView({
  accountId: selectedAccountId,
  tabs,
  selectedTab,
  onTabChange,
}) {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const smallTabsStyles = useSmallTabsStyles();
  const tabsStyles = useTabStyles();
  const { numberOfDays, fromDate, toDate } = selectedTab;
  const { data: accounts } = useAccountList();
  const selectedAccountDetails = useMemo(() => {
    return accounts?.data?.find((account) => account.id === selectedAccountId);
  }, [accounts?.data, selectedAccountId]);
  const getFinanceHistoryParams = useMemo(() => {
    const params = {
      accountId: selectedAccountId,
      externalId: selectedAccountDetails?.externalId,
      numberOfDays,
      includeTransactions: true,
    };
    if (fromDate && toDate) {
      params.from = fromDate;
      params.to = toDate;
    }
    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, numberOfDays, selectedAccountId]);
  const { data: financeHistory, isLoading } = useLastXDaysFinanceHistory(
    getFinanceHistoryParams
  );
  const [
    selectedTransactionsInfoTab,
    setSelectedTransactionsInfoTab,
  ] = useState(transactionsInfoTabs[0]);

  const cashBalance = selectedAccountDetails?.balance || 0;
  const cashBalanceHistoryChartData = toCashBalanceChartData(
    financeHistory?.cashBalances
  );

  const accountType =
    selectedAccountDetails?.class?.type || ACCOUNT_TYPE.UNKNOWN;

  const cashBalanceChartOptions = merge(
    cloneDeep(cashBalanceHistoryChartBaseOptions),
    isTablet ? cashBalanceHistoryChartOptionsMobile : {}
  );

  const totalIncoming = financeHistory?.totalIncoming || 0;
  const totalOutgoing = financeHistory?.totalOutgoing || 0;
  const totalExpense = financeHistory?.totalExpense || 0;

  const expenseCategories = financeHistory?.expenseCategories || [];
  const expenseCategoriesChartData = expenseCategories
    ?.sort(
      (dataPoint1, dataPoint2) =>
        Number(dataPoint1.amount) - Number(dataPoint2.amount)
    )
    .map(toPercentageBarDataPoint("category", Math.abs(totalExpense)));

  const top5LivingExpenseCategories =
    expenseCategories
      .sort(({ amount: amount1 }, { amount: amount2 }) => amount1 - amount2)
      ?.slice(0, 5) || [];
  const top5LivingExpenseCategoriesChartData = toTopLivingExpenseCategoriesChartData(
    top5LivingExpenseCategories
  );

  const transactions = useMemo(
    () =>
      financeHistory?.transactions
        ?.slice()
        .reverse()
        .sort(transactionsComparator) || [],
    [financeHistory?.transactions]
  );
  const {
    limit,
    increment: incrementLimit,
    reset: resetLimit,
  } = useIncrementalLimits(STEP);

  // reset the transactionsInfoTabs whenever changing account
  useEffect(() => {
    resetLimit();
    setSelectedTransactionsInfoTab(transactionsInfoTabs[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountId]);

  const _onTabChange = (event, value) => {
    onTabChange(value);
  };

  const handleTransactionsInfoTabChange = (event, value) => {
    setSelectedTransactionsInfoTab(
      transactionsInfoTabs.find((tab) => tab.title === value)
    );
  };

  const renderIfHasTransactions = (element) => {
    if (transactions?.length > 0) {
      return element;
    }
    return (
      !isLoading && (
        <Grid
          className="transactions-detail-block"
          item
          xs={12}
          container
          justify="center"
        >
          {`There are no transactions in the last ${numberOfDays} days`}
        </Grid>
      )
    );
  };

  const mobileView = (
    <Grid container>
      <Grid className="cash-balance-block" item xs={12}>
        <LoadingBackdropContainer>
          <LoadingBackdrop isOpen={isLoading} />
          <LineChartMobileWrapper
            chartTitle="Cash Balance"
            // subTitle={`(Last ${numberOfDays} days)`}
            titleValue={cashBalance}
            chartData={[
              {
                data: cashBalanceHistoryChartData,
                label: "Cash Balance",
                customOptions: lineCustomOptionsOnMobile,
              },
            ]}
          />
        </LoadingBackdropContainer>
        <DateFilter />
      </Grid>
      {renderIfHasTransactions(
        <Grid className="transactions-detail-block" item xs={12}>
          <div>
            <Tabs
              value={selectedTransactionsInfoTab.title}
              onChange={handleTransactionsInfoTabChange}
              variant="fullWidth"
              classes={{
                root: tabsStyles.tabs,
                indicator: tabsStyles.indicator,
              }}
            >
              {transactionsInfoTabs.map((tab) => (
                <Tab
                  key={tab.title}
                  value={tab.title}
                  label={tab.title}
                  classes={{
                    root: tabsStyles.tab,
                    selected: tabsStyles.selectedTab,
                  }}
                />
              ))}
            </Tabs>
          </div>
          <LoadingBackdropContainer>
            <LoadingBackdrop isOpen={isLoading} />
            <TabPanel
              className="transactions-detail-block__tab-panel"
              value={selectedTransactionsInfoTab.title}
              index={transactionsInfoTabs[0].title}
            >
              <WithLoadMore
                shown={!!transactions.length && limit < transactions.length}
                onLoadMore={incrementLimit}
              >
                <SimpleTransactionList
                  transactions={transactions}
                  limit={limit}
                />
              </WithLoadMore>
            </TabPanel>
            <TabPanel
              className="transactions-detail-block__tab-panel"
              value={selectedTransactionsInfoTab.title}
              index={transactionsInfoTabs[1].title}
            >
              <DoughnutChartComponent
                data={top5LivingExpenseCategoriesChartData}
                textTop=""
                textBottom=""
                isBottomColor
                customLegend
              />
            </TabPanel>
          </LoadingBackdropContainer>
        </Grid>
      )}
    </Grid>
  );
  const largeView = (
    <Grid container>
      <Grid className="cash-balance-block" item xs={12}>
        <div className="cash-balance-block__header">
          <div className="cash-balance-block__title">
            {`${getChartBalanceTitle(accountType)} ${formatNumberWithDollar(
              cashBalance
            )}`}
          </div>
          <div>
            <Tabs
              value={selectedTab.title}
              onChange={_onTabChange}
              indicatorColor="secondary"
              textColor="inherit"
              TabIndicatorProps={{ children: <span /> }}
              classes={{
                root: smallTabsStyles.tabs,
                flexContainer: smallTabsStyles.tabsFlexContainer,
                indicator: smallTabsStyles.indicator,
              }}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.title}
                  value={tab.title}
                  label={tab.title}
                  classes={{
                    root: smallTabsStyles.tab,
                    selected: smallTabsStyles.selectedTab,
                  }}
                />
              ))}
            </Tabs>
          </div>
        </div>
        <div className="cash-balance-block__small-header">
          <SummaryBlock
            mainText={`${formatNumberWithDollar(cashBalance)}`}
            subText="Cash Balance"
            caption="(Based on connected bank transactions)"
            actionButton={<AddAccountButton />}
          />
        </div>
        <LoadingBackdropContainer>
          <LoadingBackdrop isOpen={isLoading} />
          <LineChartComponent
            lineChartData={cashBalanceHistoryChartData}
            legendDisplay={false}
            customChartOptions={cashBalanceChartOptions}
          />
        </LoadingBackdropContainer>
      </Grid>
      {renderIfHasTransactions(
        <>
          <Grid
            className="last-x-days-block"
            item
            xs={12}
            container
            justify="center"
          >
            <Hidden smDown>
              <Grid item>
                <LastXDaysOverview
                  title={`Last ${numberOfDays} Days`}
                  parts={[
                    {
                      title: "In",
                      amount: totalIncoming,
                      color: secondaryColor,
                    },
                    {
                      title: "Out",
                      amount: Math.abs(totalOutgoing),
                      color: primaryColor,
                    },
                  ]}
                />
              </Grid>
            </Hidden>
            <Grid item xs={10} md={5}>
              <PercentageBar
                chartColors={expensesCategoryChartColor(expenseCategories)}
                chartIcons={expensesCategoryChartIcon(expenseCategories)}
                defaultTitle="Expense Categories"
                parts={expenseCategoriesChartData}
                sortDirection={SORT_DIRECTION.DESCENDING}
              />
            </Grid>
          </Grid>
          <Grid className="transactions-detail-block" item xs={12}>
            <LoadingBackdropContainer>
              <LoadingBackdrop isOpen={isLoading} />
              <WithLoadMore
                shown={!!transactions.length && limit < transactions.length}
                onLoadMore={incrementLimit}
              >
                <TransactionsTable transactions={transactions} limit={limit} />
              </WithLoadMore>
            </LoadingBackdropContainer>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <div className="account-view">
      <div className="account-view__main-wrapper">
        <Hidden mdUp>
          <Grid container className="ins-outs-mobile-container ">
            <Grid item xs={12}>
              <SummaryBlock
                mainText="Ingoing and Outgoing"
                // subText={`(Last ${numberOfDays} days)`}
              />
            </Grid>
            <Grid item xs={12} className="ins-outs-mobile-container__chart">
              <LoadingBackdropContainer>
                <LoadingBackdrop isOpen={isLoading} />
                <IncomeExpenseHorizontalBarChart
                  totalIncome={totalIncoming}
                  totalExpense={totalOutgoing}
                />
              </LoadingBackdropContainer>
            </Grid>
          </Grid>
        </Hidden>
        {isMobile ? mobileView : largeView}
      </div>
    </div>
  );
}

export default AccountView;
